// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));
// enable Stimulus debug mode in development
application.debug = process.env.NODE_ENV === "development";
// alternatively:
// window.Stimulus = application;

// https://www.betterstimulus.com/turbolinks/teardown.html
document.addEventListener("turbolinks:before-cache", () => {
  application.controllers.forEach(controller => {
    if (typeof controller.teardown === "function") {
      controller.teardown();
    }
  });
});

window.Jshost = require("../js_host_for_ios_app.js");
