import ApplicationController from "./application_controller";
import dialogPolyfill from "dialog-polyfill";

export default class extends ApplicationController {
  static targets = ["editable", "editor"];

  connect() {
    this.path = this.data.get("path");
  }

  disconnect() {
    this.dialog = undefined;
    this.form = undefined;
  }

  displayDialog(event) {
    event.preventDefault();
    this.dialog = this._createDialog();
    this.editor = this._createEditor();
    this.dialog.querySelector("form [data-js-form-dialog--body]").innerHTML = ""; // clear in case already used
    this.dialog.querySelector("form [data-js-form-dialog--body]").appendChild(this.editor);
    this._adjustDialog();

    this.eventListener = () => {
      this.dialogClosed();
    };
    this.dialog.addEventListener("close", this.eventListener);
    this.dialog.showModal();
  }

  _adjustDialog() {
    //place cursor at beginning and add return;
    this.editor.value = "\n\n" + this.editor.value;
    this.editor.setSelectionRange(0, 0);
  }

  dialogClosed() {
    this.dialog.removeEventListener("close", this.eventListener);
    if (this.dialog.returnValue !== "submit") {
      return;
    }
    // console.log(`submit ${this.path}`);
    const csrfToken = $("meta[name='csrf-token']").attr("content");
    const data = {job_current_note: {text: this.editor.value}, _method: "put", authenticity_token: csrfToken};
    fetch(this.path, {
      method: "PUT", // *GET, POST, PUT, DELETE, etc.
      mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        this.element.outerHTML = json.partial;
      });
  }


  _createDialog() {
    let dialog = document.querySelector("[data-js-form-dialog]");
    dialogPolyfill.registerDialog(dialog);
    const submit = dialog.querySelector("button[value=submit]");
    if (submit) submit.innerHTML = "Save";
    return dialog;
  }

  // some reference to https://johnbeatty.co/2018/04/11/lets-make-an-editable-header-using-stimulus-js/

  _createEditor() {
    let editor;
    if (this.hasEditorTarget) {
      editor = this.editorTarget.cloneNode(true);
      editor.classList.remove("dialog-editable--hidden");
    } else {
      editor = document.createElement("textarea");
    }
    return editor;
  }
}
