/* eslint-env es6 */

import ApplicationController from "./application_controller";

const Choices = require("choices.js");
const SingleSelectDynamicChoices = require("../single_select_dynamic_choices.js");

export default class extends ApplicationController {
  static targets = ["select", "revealable", "hideable"];

  connect() {
    this.baseSearchUrl = this.data.get("searchUrl");
    this.select = this.hasSelectTarget ? this.selectTarget : this.element.querySelectorAll("select")[0];
    this.choices = new Choices(this.select, {
      allowHTML: true,
      noChoicesText: "No matches",
      searchPlaceholderValue: "Start typing part of an already used destination",
      shouldSort: false,
      searchFloor: 2,
      searchChoices: false
      // searchResultLimit: 7
    });
    this.choicesWrapper = new SingleSelectDynamicChoices(this.select, this.choices,{searchFunction: (query) => this.search(query),
      valueKey: "id", labelKey: "display"});
  }

  reveal(event) {
    event.preventDefault();
    event.stopPropagation();
    // reveal
    for(let target of this.revealableTargets) {
      target.style.display = "";
    }
    for(let target of this.hideableTargets) {
      // reveal the reveal button!
      target.style.display = "none";
    }
    // this.choices.ajax((callback) => {
    //   this.search(callback);
    // });
    // this.choices.showDropdown();
  }

  search(query, callback) {
    return fetch(`${this.baseSearchUrl}?q=${query}`, {
      // method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      }
    }
    )
      .then(function (response) {
        return response.json().then((data) => {
          return data;
        });
      })
      .catch(function (error) {
        console.warn(error);
      })
    ;
  }

  cancel() {
    event.preventDefault();
    event.stopPropagation();
    // reveal the reveal button!
    for(let target of this.hideableTargets) {
      target.style.display = "";
    }
    for(let target of this.revealableTargets) {
      target.style.display = "none";
    }
  }

  disconnect() {
    this.choices.destroy();
  }
}
