/* eslint-env es6 */

import ApplicationController from "./application_controller";
import "choices.js/public/assets/styles/choices.css";
const Choices = require("choices.js");

export default class extends ApplicationController {
  static targets = ["select"];

  connect() {
    this.select = this.hasSelectTarget ? this.selectTarget : this.element.querySelectorAll("select")[0];
    this.choices = new Choices(this.select, this.choicesConfig());
    if(this.select.multiple) {
      this.select.addEventListener("change", (event) => {
        this.choices.hideDropdown();
      });
    }
  }

  // https://www.betterstimulus.com/turbolinks/teardown.html
  teardown() {
    this.choices.destroy();
  }

  choicesConfig() {
    let shouldSort = !!this.data.get("sort") || false;
    let choicesConfig = {
      allowHTML: true,
      shouldSort: shouldSort,
      searchResultLimit: 7
    };
    if(this.select.multiple) {
      choicesConfig.removeItemButton = true;
    }
    return choicesConfig;
  }
}
