/* eslint-env es6 */

import ChoicesController from "./choices_controller";

/*
  selector wraps around the select and needs to be explicit
  button can be implicit
  typical haml:

  %fieldset.choices--combobox(data-controller="combobox")
    %div(data-combobox-target="selector")
      = select_tag("", options_for_select([current_value] + valid_values), class: "form-control")
    .input-group
      = text_field_tag(name, current_value, class: "form-control", data: {target: "combobox.textInput"})
      %span.input-group-btn
        .btn.btn-default= fas_icon("caret-down")
 */
export default class extends ChoicesController {
  static targets = ["selector", "textInput", "button"];

  connect() {
    super.connect();
    this.button = this.hasButtonInput ? this.buttonTarget : this.element.querySelector(".btn");
    this.select.addEventListener("blur", () => this.selectDone());
    this.select.addEventListener("change", () => this.selectDone());
    this.button.addEventListener("click", () => this.showSelect());
    this.selectorTarget.style.display = "none";

    // https://github.com/Choices-js/Choices/issues/811#issuecomment-604555828
    this.choices.input.element.addEventListener("blur", () => {
      this.selectDone();
    });

  }
  selectDone() {
    this.button.style.display = "";
    this.textInputTarget.style.display = "";
    this.selectorTarget.style.display = "none";
    this.textInputTarget.value = this.choices.getValue(true);
  }
  showSelect() {
    this.button.style.display = "none";
    this.textInputTarget.style.display = "none";
    this.selectorTarget.style.display = "";
    this.selectorTarget.style.zIndex = 999;
    this.selectorTarget.style.position = "relative";
    this.selectorTarget.style.top = 0;
    this.choices.showDropdown();
  }
}
