/* eslint-env es6 */

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect() {
    this.element.querySelectorAll("tr td:not(.not-clickable):not(.link):not(.buttons)").forEach((elem) => {
      elem.addEventListener("click", (event) => {
        this.rowClicked(event);
      });
    });
  }

  rowClicked(event) {
    let td = event.currentTarget;
    let a = td.parentNode.querySelector("a[href]");
    let href = a && a.getAttribute("href");
    if (href) {
      // this controller is being double added (some bad interaction with datatables), so to prevent weirdness, we just do this once and prevent propagation.
      event.stopImmediatePropagation();
      event.preventDefault();
      Turbolinks.visit(href);
    }
  }
}
