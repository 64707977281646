/* eslint-env es6 */

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["packageNumber", "errorMessage"];

  connect() {
    if (!this.hasPackageNumberTarget) {
      console.warn("no packageNumber target found for package-picker");
    }
    if (!this.hasErrorMessageTarget) {
      console.warn("no errorMessage target found for package-picker");
    }
    this.rootPath = this.data.get("rootPath");
  }

  pick(event) {
    let regexp = new RegExp(".*(/packages/[0-9]+)");
    let match = regexp.exec(this.packageNumberTarget.value);
    if (match !== null) {
      this.visitEmbeddedPath(match[1]);
    } else {
      this.search(this.packageNumberTarget.value, (pakkage) => {
        this.view(pakkage);
      });
    }
    // just use it if it's a URL
    // otherwise look it up using search()
    // Turbolinks.visit(`/packages/search?${this.packageNumberTarget.value}`);
  }

  view(pakkage) { // because package is a reserved word
    if (pakkage.path) {
      this.visitEmbeddedPath(pakkage.path);
    } else if(pakkage.otherUrl) {
      Turbolinks.visit(pakkage.otherUrl);
    } else if(this.hasErrorMessageTarget) {
      this.errorMessageTarget.innerText = pakkage.error || "Couldn't find package";
    } else{
      alert(pakkage.error || "Couldn't find package");
    }
  }

  visitEmbeddedPath(packagePath) {
    Turbolinks.visit(`${this.rootPath}${packagePath}`);
  }

  search(query, callback) {
    return fetch(`/packages/search.json?q=${query}`, {
      // method: "GET", // *GET, POST, PUT, DELETE, etc.
      // mode: "same-origin", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, same-origin, *omit
      headers: {
        "Content-Type": "application/json",
        // "Content-Type": "application/x-www-form-urlencoded",
      }
    }
    )
      .then(response => response.json())
      .then(data => callback(data))
      .catch((error) => {
        console.warn(error);
      })
    ;
  }

}
